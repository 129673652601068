
import Vue from 'vue';
import { routesNames } from '@/router';

import LocaleSwitcher from '@/components/general/utils/LocaleSwitcher.vue';

import CART from '@/store/modules/CartModule';
import USER from '@/store/modules/UserModule';
import UTILS from '@/store/modules/UtilityModule';
import BOOKING from '@/store/modules/BookingModule';

export default Vue.extend({
	name: 'NavBarTopFocus',

	components: { LocaleSwitcher },

	data() {
		return {
			step_flow: [
				'truck',
				'time',
				routesNames.upsells_1,
				routesNames.upsells_2,
				routesNames.summary
			],
			route_flow: [routesNames.upsells_1, routesNames.upsells_2, routesNames.summary],
			timer: '' as any
		};
	},

	computed: {
		CART: () => CART,
		USER: () => USER,
		UTILS: () => UTILS,
		BOOKING: () => BOOKING,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.mdAndDown;
		},

		currentStep(): number {
			const current_step = this.step_flow.indexOf(this.$route.name as routesNames);
			return current_step + 1;
		},

		orange123(): string {
			return this.$vuetify.theme.themes.light.orange123 as string;
		}
	},

	watch: {
		currentStep() {
			this.timerCheck();
		}
	},

	mounted() {
		this.timerCheck();
	},

	methods: {
		async goBack(): Promise<void> {
			const current = this.route_flow.indexOf(this.$route.name as routesNames);

			if (current < 1) {
				await this.leavingBookingFlow();
				return;
			}

			this.$router.push({ name: this.route_flow[current - 1] });
		},

		async leavingBookingFlow(): Promise<void> {
			if (
				!(await this.$dialogs.confirm(
					this.$t('msg.leaving_booking_process') as string,
					this.$t('msg.discard_booking') as string,
					{
						color: 'grey--text text--darken-3'
					}
				))
			) {
				return;
			}

			this.UTILS.setPageLoader(true);

			const getPartnerName = this.BOOKING.bookingPartnerName;

			if (getPartnerName) {
				this.$router.replace('/' + getPartnerName);
				return;
			}

			// home is clearing everything in mounted - it use to be here but we need to clear in any situation when getting to home right ?
			this.$router.replace({ name: routesNames.home });
		},

		startTimer(): void {
			const time = 5 * 60;
			let tmp = time;

			setInterval(() => {
				const total_sec = tmp--;
				const m = Math.trunc(total_sec / 60);
				const s = total_sec - m * 60 + '';
				this.timer = +m + ':' + (s.length > 1 ? '' : '0') + s;
				tmp !== 0 || (tmp = time);
			}, 1000);
		},

		timerCheck(): void {
			if (this.$route.name === routesNames.summary && !this.timer) {
				this.startTimer();
			}
		}
	}
});
