var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app-bar',{attrs:{"app":"","flat":"","clipped-right":""}},[(!_vm.isMobile)?_c('div',{staticStyle:{"height":"64px","display":"block !important","width":"100%"}},[_c('div',{staticClass:"container",staticStyle:{"max-width":"1440px !important"}},[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.leavingBookingFlow.apply(null, arguments)}}},[_c('nuxt-img',{staticClass:"ml-0 pl-0 mt-0 hidden-xs-only",attrs:{"src":`${_vm.staticAssetsPath}/press/logo/long.svg`,"alt":"123-Transporter logo long","height":"64px","width":"192px"}})],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-6 spartan-font font-weight-black",attrs:{"text":"","color":"grey darken-2"},on:{"click":function($event){$event.stopPropagation();return _vm.goBack()}}},[_c('v-icon',{staticClass:"pb-1 mr-1",attrs:{"color":"grey darken-2"}},[_vm._v("mdi-arrow-left-thick")]),_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('actions.back'))+"\n\t\t\t")],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('div',{staticClass:"spartan-font font-weight-bold orange123--text"},[_vm._v("\n\t\t\t\t"+_vm._s(`${_vm.currentStep}/${_vm.step_flow.length}`)+"\n\t\t\t")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.timer)?_c('div',{staticClass:"spartan-font font-weight-bold grey--text px-2 text-right",staticStyle:{"width":"250px"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('booking.summary.timer', { time: _vm.timer }))+"\n\t\t\t")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex mx-auto",staticStyle:{"width":"100%","max-width":"1445px"}},[_c('v-container',{style:(`height: .001px; border-bottom: .6rem solid #c1c1c1; border-bottom-left-radius: 1rem;`)}),_vm._v(" "),_c('v-container',{style:(`height: .001px; border-bottom: .6rem solid #c1c1c1;`)}),_vm._v(" "),_c('v-container',{style:(`height: .001px; border-bottom: .6rem solid ${
					_vm.currentStep === 3 ? _vm.orange123 : '#c1c1c1'
				};`)}),_vm._v(" "),_c('v-container',{style:(`height: .001px; border-bottom: .6rem solid ${
					_vm.currentStep === 4 ? _vm.orange123 : '#c1c1c1'
				};`)}),_vm._v(" "),_c('v-container',{style:(`height: .001px; border-bottom: .6rem solid ${
					_vm.currentStep === 5 ? _vm.orange123 : '#c1c1c1'
				}; border-bottom-right-radius: 1rem`)})],1)]):_c('div',{staticStyle:{"height":"56px","display":"block !important","width":"100%"}},[_c('div',{staticClass:"container",staticStyle:{"height":"56px"}},[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.leavingBookingFlow.apply(null, arguments)}}},[_c('nuxt-img',{staticClass:"ml-0 mt-1",attrs:{"src":`${_vm.staticAssetsPath}/press/logo/square-2.svg`,"alt":"123-Transporter logo square","height":"36px","width":"36px"}})],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 spartan-font font-weight-black",attrs:{"text":"","small":"","color":"grey darken-2"},on:{"click":function($event){$event.stopPropagation();return _vm.goBack()}}},[_c('v-icon',{staticClass:"pb-1 mr-1",attrs:{"color":"grey darken-2","small":""}},[_vm._v("\n\t\t\t\t\tmdi-arrow-left-thick\n\t\t\t\t")]),_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('actions.back'))+"\n\t\t\t")],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('div',{staticClass:"spartan-font font-weight-bold orange123--text",staticStyle:{"font-size":"0.75rem"}},[_vm._v("\n\t\t\t\t"+_vm._s(`${_vm.currentStep}/${_vm.step_flow.length}`)+"\n\t\t\t")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.timer)?_c('div',{staticClass:"spartan-font font-weight-bold grey--text text-right",staticStyle:{"width":"100px","font-size":"0.8rem"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('booking.summary.timer_mobile', { time: _vm.timer }))+"\n\t\t\t")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex mx-auto",staticStyle:{"width":"100%"}},[_c('v-container',{style:(`height: .001px; border-bottom: .5rem solid #c1c1c1; border-bottom-left-radius: 1rem;`)}),_vm._v(" "),_c('v-container',{style:(`height: .001px; border-bottom: .5rem solid #c1c1c1;`)}),_vm._v(" "),_c('v-container',{style:(`height: .001px; border-bottom: .5rem solid ${
					_vm.currentStep === 3 ? _vm.orange123 : '#c1c1c1'
				};`)}),_vm._v(" "),_c('v-container',{style:(`height: .001px; border-bottom: .5rem solid ${
					_vm.currentStep === 4 ? _vm.orange123 : '#c1c1c1'
				};`)}),_vm._v(" "),_c('v-container',{style:(`height: .001px; border-bottom: .5rem solid ${
					_vm.currentStep === 5 ? _vm.orange123 : '#c1c1c1'
				}; border-bottom-right-radius: 1rem`)})],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('locale-switcher',_vm._g({},_vm.$listeners))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }