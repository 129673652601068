import { getAllPaths, routesNames } from '@/router';
import { IPartner, IDataPerBu } from '@/types/misc_data';

export default {
	// AT
	at: {
		// Home.vue
		youtube_id: 'ePGlG8mC028',
		partner_youtube_id: '7y83vDLXtb0',
		phone_fee: 3.64,
		email_jobs: 'karriere@123-transporter.at',
		email_fleet_partner: 'flottenpartner@123-transporter.at',
		email_location_partner: 'standortpartner@123-transporter.at',
		long_term_rent_allowed: false,
		partners: [
			{
				id: 'd66b91a2-d9b2-4b91-958b-c713adb289cc',
				name: 'obi',
				route_name: routesNames.partner_obi,
				url: getAllPaths(routesNames.partner_obi),
				t_key: 'official_partners.obi',
				logo: '/img/partners/logo_obi.svg',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_obi.svg',
				locations: []
			},
			{
				id: 'd66b91a2-d9b2-4b91-958b-c713adb289cd',
				name: 'bellaflora',
				route_name: routesNames.partner_bellaflora,
				url: getAllPaths(routesNames.partner_bellaflora),
				t_key: 'official_partners.bellaflora',
				logo: '/img/partners/logo_bellaflora.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_bellaflora.svg',
				locations: []
			},
			{
				id: '87a121d8-deff-4044-9380-ff8653de6edb',
				name: 'dehner',
				route_name: routesNames.partner_dehner,
				url: getAllPaths(routesNames.partner_dehner),
				t_key: 'official_partners.dehner',
				logo: '/img/partners/logo_dehner.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_dehner.png',
				locations: []
			},
			{
				id: '1c83c256-12f6-4105-9f32-7dec1e39b1eb',
				name: 'obi_bauwelt',
				route_name: routesNames.partner_bauwelt,
				url: getAllPaths(routesNames.partner_bauwelt),
				t_key: 'official_partners.obi_bauwelt',
				logo: '/img/partners/map_icon_bauwelt.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_bauwelt.png',
				locations: []
			},
			{
				id: '16b5f9c8-712b-4899-a1f1-e2e6af0a5879',
				name: 'obi_teubl',
				route_name: routesNames.partner_teubl,
				url: getAllPaths(routesNames.partner_teubl),
				t_key: 'official_partners.obi_teubl',
				logo: '/img/partners/logo_teubl.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_teubl2.png',
				locations: []
			},
			{
				id: '17a202d7-63b0-452f-bdef-2d3414ebb423',
				name: 'raiffeisen_lagerhaus',
				route_name: routesNames.partner_raiffeisen_lagerhaus,
				url: getAllPaths(routesNames.partner_raiffeisen_lagerhaus),
				t_key: 'official_partners.raiffeisen_lagerhaus',
				logo: '/img/partners/logo_raiffeisen_lagerhaus.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_raiffeisen_lagerhaus.png',
				locations: []
			},
			{
				id: '0190d9b8-3540-7072-8a04-d99d6f6d88e2',
				name: 'hornbach',
				route_name: routesNames.partner_hornbach,
				url: getAllPaths(routesNames.partner_hornbach),
				t_key: 'official_partners.hornbach',
				logo: '/img/partners/hornbach-logo-new.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/hb_logo_map.png',
				locations: []
			},
			{
				id: '',
				name: 'arbo',
				route_name: '',
				url: '',
				t_key: 'official_partners.arbo',
				logo: '/img/partners/arbo-logo.jpg',
				logo_height: null,
				logo_width: null,
				map_icon: '',
				locations: []
			}
		],
		trucks: {
			min_size_in_m3: {
				l: 6,
				xl: 11,
				xxl: 11
			},
			price_filter: { min: 9, max: 44, step: 5 }
		},
		map_center: {
			lat: 47.9,
			lng: 14.4,
			zoom: 7
		},
		map_center_mobile: {
			lat: 47.9,
			lng: 14.4,
			zoom: 7
		},
		// Assets
		images: {
			home_pricing_cards: {
				l: '/img/pricing_cards/pricing-card-l.webp',
				xl: '/img/pricing_cards/pricing-card-xl.webp',
				xxl: '/img/pricing_cards/pricing-card-xxl.webp'
			},
			truck_side_mockups: {
				l: '/img/truck_mockups/L_new_w.webp',
				xl: '/img/truck_mockups/XL.png',
				xxl: '/img/truck_mockups/XXL.png',
				advantages: '/img/truck_mockups/L_new_w.webp'
			},
			jobs: {
				second_box: '/img/jobs/jobs_bronko.jpeg'
			},
			sustainability: {
				second_box: '/img/sustainability/sustain_2.jpg'
			},
			our_app: {
				top_section: '/img/app-info/top_section.png',
				how_section: '/img/app-info/how_section.png',
				discover_section: '/img/app-info/discover_section.png',
				dl_section: '/img/app-info/download_section.png'
			},
			about_us: {
				second_image: '/img/about-us/about_2.png',
				fourth_image: '/img/about-us/about_flottenpartner.jpg'
			},
			location_partner: {
				van: '/img/location-partner/van_xl_left_back_t_c.png'
			}
		},
		media_logos: [
			[
				{
					size: {
						width: 200,
						height: 52
					},
					link: 'https://www.diepresse.com/',
					img: '/img/index-page/known-from/logo_die_presse.png',
					description: 'Logo von Die Presse'
				},
				{
					size: {
						width: 180,
						height: 41
					},
					link: 'https://kurier.at/amp/wirtschaft/start-up-123-transporter-erweitert-transporter-sharing/402042756',
					img: '/img/index-page/known-from/logo_kurier.png',
					description: 'Logo von Kurier'
				},
				{
					size: {
						width: 250,
						height: 30
					},
					link: 'https://brutkasten.com/artikel/123-transporter-startet-in-karnten-und-salzburg',
					img: '/img/index-page/known-from/logo_brutkasten.png',
					description: 'Logo von Brutkasten Media'
				},
				{
					size: {
						width: 77,
						height: 70
					},
					link: 'https://www.krone.at/2777724',
					img: '/img/index-page/known-from/logo_kronen_zeitung.png',
					description: 'Logo von Kronen Zeitung unabhängig'
				}
			]
		]
	} as IDataPerBu,
	de: {
		// Home.vue
		youtube_id: 'ePGlG8mC028',
		partner_youtube_id: '7y83vDLXtb0',
		phone_fee: 2.99,
		email_jobs: 'karriere@123-transporter.de',
		email_fleet_partner: 'flottenpartner@123-transporter.de',
		email_location_partner: 'standortpartner@123-transporter.de',
		long_term_rent_allowed: true,

		partners: [
			{
				id: '01936991-b4ec-732c-972b-7e86b7fba3ec',
				name: 'hornbach',
				route_name: routesNames.partner_hornbach,
				url: getAllPaths(routesNames.partner_hornbach),
				t_key: 'official_partners.hornbach',
				logo: '/img/partners/hornbach-logo-new.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/hornbach-icon-small.png',
				locations: []
			}
		] as IPartner[],

		trucks: {
			min_size_in_m3: {
				l: 6,
				xl: 12,
				xxl: 12
			},
			price_filter: { min: 9, max: 44, step: 5 }
		},
		map_center: {
			lat: 50.25,
			lng: 10.1,
			zoom: 7
		},
		map_center_mobile: {
			lat: 50.8,
			lng: 10.5,
			zoom: 6
		},

		// Assets
		images: {
			home_pricing_cards: {
				l: '/img/pricing_cards/pricing-card-l.webp',
				xl: '/img/pricing_cards/pricing-card-xl.webp',
				xxl: '/img/pricing_cards/pricing-card-xxl.webp'
			},
			truck_side_mockups: {
				l: '/img/truck_mockups/L_de.png',
				xl: '/img/truck_mockups/XL_de.png',
				xxl: '/img/truck_mockups/XXL_de.png'
			},
			jobs: {
				second_box: '/img/jobs/jobs_bronko.jpeg'
			},
			sustainability: {
				second_box: '/img/sustainability/sustain_2.jpg'
			},
			our_app: {
				top_section: '/img/app-info/top_section.png',
				how_section: '/img/app-info/how_section.png',
				discover_section: '/img/app-info/discover_section.png',
				dl_section: '/img/app-info/download_section.png'
			},
			about_us: {
				second_image: '/img/about-us/about_2.png',
				fourth_image: '/img/about-us/about_flottenpartner.jpg'
			},
			location_partner: {
				van: '/img/location-partner/van_xl_left_back_t_c.png'
			}
		},
		media_logos: [
			[
				{
					link: 'https://www.diepresse.com/',
					img: '/img/index-page/known-from/logo_die_presse.png',
					description: 'Logo von Die Presse'
				},
				{
					link: 'https://www.noen.at/',
					img: '/img/index-page/known-from/logo_noen.png',
					description: 'Logo von NÖN'
				},
				{
					link: 'https://www.wienerzeitung.at/',
					img: '/img/index-page/known-from/logo_wiener_zeitung.png',
					description: 'Logo von Wiener Zeitung'
				},
				{
					link: 'https://kurier.at/amp/wirtschaft/start-up-123-transporter-erweitert-transporter-sharing/402042756',
					img: '/img/index-page/known-from/logo_kurier.png',
					description: 'Logo von Kurier'
				}
			],
			[
				{
					link: 'https://flotte.at/index.php',
					img: '/img/index-page/known-from/logo_flotte.png',
					description: 'Logo von Flotte',
					background: true
				},
				{
					link: 'https://www.klippmagazin.at/',
					img: '/img/index-page/known-from/logo_klipp.png',
					description: 'Logo von Klipp Magazin',
					background: true
				},
				{
					link: 'https://unterkaerntner.at/startseite/',
					img: '/img/index-page/known-from/logo_unterkaerntner_nachrichten.png',
					description: 'Logo von Unterkärtner Nachrichten'
				},
				{
					size: {
						width: 160
					},
					link: 'https://brutkasten.com/artikel/123-transporter-startet-in-karnten-und-salzburg',
					img: '/img/index-page/known-from/logo_brutkasten.png',
					description: 'Logo von Brutkasten Media'
				}
			],
			[
				{
					link: 'https://www.trendingtopics.eu/de/',
					img: '/img/index-page/known-from/logo_trending_topics.png',
					description: 'Logo von Trending Topics'
				},
				{
					link: 'https://grazer.at/',
					img: '/img/index-page/known-from/logo_dergrazer.png',
					description: 'Logo von DerGrazer'
				},

				{
					link: 'https://regal.at/',
					img: '/img/index-page/known-from/logo_regal.png',
					description: 'Logo von Regal'
				},
				{
					link: 'https://www.kleinezeitung.at/',
					img: '/img/index-page/known-from/logo_kleine_zeitung.png',
					description: 'Logo von Kleine Zeitung'
				}
			],
			[
				{
					size: {
						width: 'auto',
						height: 60
					},
					link: 'https://retail.at/',
					img: '/img/index-page/known-from/logo_retail_at.png',
					description: 'Logo von Retail'
				},
				{
					link: 'https://www.newbusiness.at/',
					img: '/img/index-page/known-from/logo_new_business.png',
					description: 'Logo von New Business'
				},
				{
					link: 'https://medianet.at/',
					img: '/img/index-page/known-from/logo_medianet.png',
					description: 'Logo von Medianet'
				},
				{
					link: 'https://www.wirtschafts-nachrichten.com/',
					img: '/img/index-page/known-from/logo_wirtschaftsnachrichten_donauraum_west_sued.png',
					description: 'Logo von Wirtschaftsnachrichten Donauraum West Süd'
				}
			],
			[
				{
					link: 'https://www.cash.at/',
					img: '/img/index-page/known-from/logo_cash.png',
					description: 'Logo von Cash'
				},
				{
					link: 'https://www.leadersnet.at/',
					img: '/img/index-page/known-from/logo_leadersnet.png',
					description: 'Logo von Leadersnet'
				},
				{
					link: 'https://oevz.com/',
					img: '/img/index-page/known-from/logo_oesterreichische_verkehrszeitung.png',
					description: 'Logo von Österreichische Verkehrszeitung'
				},
				{
					link: 'https://volksblatt.at/',
					img: '/img/index-page/known-from/logo_ooe_volksblatt.png',
					description: 'Logo von Oberösterreichische Volksblatt'
				}
			],
			[
				{
					link: 'https://app.stwi.at/',
					img: '/img/index-page/known-from/logo_steirische_wirtschaft.png',
					description: 'Logo von Steirische Wirtschaft'
				},
				{
					link: 'https://www.tips.at/',
					img: '/img/index-page/known-from/logo_tips_at.png',
					description: 'Logo von Tips'
				},
				{
					link: 'https://www.vienna.at/',
					img: '/img/index-page/known-from/logo_vienna_at.png',
					description: 'Logo von Vienna.at'
				},
				{
					link: 'https://www.die-wirtschaft.at/',
					img: '/img/index-page/known-from/logo_die_wirtshaft.png',
					description: 'Logo von die Wirtschaft'
				}
			],
			[
				{
					link: 'https://www.wirtschaftszeit.at/',
					img: '/img/index-page/known-from/logo_wirtschaftszeit.png',
					description: 'Logo von Witschaftszeit Magazin'
				},
				{
					link: 'https://www.report.at/',
					img: '/img/index-page/known-from/logo_report.png',
					description: 'Logo von Report'
				},
				{
					link: 'https://businessleben.de/',
					img: '/img/index-page/known-from/logo_business_leben.png',
					description: 'Logo von Business Leben Magazin'
				},
				{
					link: 'https://autoundwirtschaft.at/',
					img: '/img/index-page/known-from/logo_auto_information.png',
					description: 'Logo von Auto Information'
				}
			],
			[
				{
					size: {
						width: 'auto',
						height: 70
					},
					link: 'https://www.arboe.at/medien/verkehrsradio',
					img: '/img/index-page/known-from/logo_arboe_verkehrsradio.png',
					description: 'Logo von ARBÖ Verkehrsradio'
				},
				{
					size: {
						width: 'auto',
						height: 70
					},
					link: 'https://www.krone.at/2777724',
					img: '/img/index-page/known-from/logo_kronen_zeitung.png',
					description: 'Logo von Kronen Zeitung unabhängig'
				},
				{
					size: {
						width: 'auto',
						height: 70
					},
					link: 'https://www.meinbezirk.at/',
					img: '/img/index-page/known-from/logo_meinbezirk_bezirksrundschau.png',
					description: 'Logo von Mein Bezirk BezirksRundSchau'
				},
				{
					size: {
						width: 'auto',
						height: 70
					},
					link: 'https://wienerbezirksblatt.at/',
					img: '/img/index-page/known-from/logo_wiener_bezirks_blatt.png',
					description: 'Logo von Wiener Bezirksblatt'
				}
			],
			[
				{
					size: {
						width: 'auto',
						height: 45
					},
					link: 'http://www.frauen-business.at/',
					img: '/img/index-page/known-from/logo_frauen_business.png',
					description: 'Logo von Frauen Business'
				}
			]
		]
	} as IDataPerBu,
	cz: {
		// Home.vue
		youtube_id: 'UfhhFimX68A',
		partner_youtube_id: 'ec1lha8hiI4',
		phone_fee: null,
		email_jobs: 'support@123-transporter.cz',
		email_fleet_partner: 'support@123-transporter.cz',
		email_location_partner: 'standortpartner@123-transporter.cz',
		long_term_rent_allowed: true,

		partners: [
			{
				id: '2ac8da54-bfb2-4c8a-b067-680bb6ffe76a',
				name: 'obi',
				route_name: routesNames.partner_obi,
				url: getAllPaths(routesNames.partner_obi),
				t_key: 'official_partners.obi',
				logo: '/img/partners/logo_obi.svg',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_obi.svg',
				locations: []
			},
			{
				id: 'd3f43523-ce8c-42ab-8109-a675e3b3d909',
				name: 'sconto',
				route_name: routesNames.partner_sconto,
				url: getAllPaths(routesNames.partner_sconto),
				t_key: 'official_partners.sconto',
				logo: '/img/partners/logo_sconto.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_sconto2.png',
				locations: []
			},
			{
				id: '01909830-9c68-724c-a9e3-753bbbd6b659',
				name: 'hornbach',
				route_name: routesNames.partner_hornbach,
				url: getAllPaths(routesNames.partner_hornbach),
				t_key: 'official_partners.hornbach',
				logo: '/img/partners/hornbach-logo-new.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/hornbach-icon-small.png',
				locations: []
			},
			{
				id: '0194e10e-aa2c-72d3-83dc-6a9e5329e191',
				name: 'olympia',
				route_name: routesNames.partner_olympia,
				url: getAllPaths(routesNames.partner_olympia),
				t_key: 'official_partners.olympia',
				logo: '/img/partners/olympia-logo.jpg',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/olympia-icon-small.jpg',
				locations: []
			},
			{
				id: '01944019-ca0a-7074-993a-6169f289faad',
				name: 'bauhaus',
				route_name: routesNames.partner_bauhaus,
				url: getAllPaths(routesNames.partner_bauhaus),
				t_key: 'official_partners.bauhaus',
				logo: '/img/partners/bauhaus-logo.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/bauhaus-icon-small.png',
				locations: []
			},
			{
				id: '0194699e-f77f-705c-8d78-0540717d074e',
				name: 'uni_hobby',
				route_name: routesNames.partner_uni_hobby,
				url: getAllPaths(routesNames.partner_uni_hobby),
				t_key: 'official_partners.uni_hobby',
				logo: '/img/partners/uni-hobby-logo.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/uni-hobby-icon-small.png',
				locations: []
			},
			{
				id: '01959555-61db-7250-be78-8d4b913ad1ae',
				name: 'tesco',
				route_name: routesNames.partner_tesco,
				url: getAllPaths(routesNames.partner_tesco),
				t_key: 'official_partners.tesco',
				logo: '/img/partners/tesco-logo.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/tesco-icon-small.png',
				locations: []
			}
		] as IPartner[],
		trucks: {
			min_size_in_m3: {
				l: 6,
				xl: 12,
				xxl: 12
			},
			price_filter: { min: 390, max: 1090, step: 100 }
		},
		map_center: {
			lat: 49.82,
			lng: 15.4,
			zoom: 8
		},
		map_center_mobile: {
			lat: 49.82,
			lng: 15.4,
			zoom: 7
		},

		// Assets
		images: {
			home_pricing_cards: {
				l: '/img/pricing_cards/pricing-card-l.webp',
				xl: '/img/pricing_cards/pricing-card-xl.webp',
				xxl: '/img/pricing_cards/pricing-card-xxl.webp'
			},
			truck_side_mockups: {
				l: '/img/truck_mockups/l-new-cz-img.png',
				xl: '/img/truck_mockups/xl-new-cz-img.png',
				xxl: '/img/truck_mockups/xxl-new-cz-img.png',
				advantages: '/img/index-page/advantages/van-cz.png'
			},
			jobs: {
				second_box: '/img/jobs/incoolbator.png',
				team: '/img/jobs/cz_team.jpg'
			},
			sustainability: {
				second_box: '/img/jobs/jobs_123_van_cz.jpg'
			},
			our_app: {
				top_section: '/img/app-info/top_section_cz.png',
				how_section: '/img/app-info/how_section_cz.png',
				discover_section: '/img/app-info/discover_section_cz.png',
				dl_section: '/img/app-info/download_section_cz.png'
			},
			about_us: {
				second_image: '/img/about-us/about_2.png',
				fourth_image: '/img/about-us/about_flottenpartner.jpg'
			},
			location_partner: {
				van: '/img/truck_mockups/XL_cz.png'
			}
		},
		media_logos: [
			[
				{
					link: 'https://www.super.cz/clanek/komercni-clanky-revoluce-v-pujcovani-dodavek-dorazila-do-ceska-s-123-transporter-1503442',
					img: '/img/index-page/known-from/cz/logo_super_cz.png',
					description: 'Logo from Super CZ'
				},
				{
					link: 'https://www.metro.cz/protext/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transpor.A240319_152300_metro-protext_air',
					img: '/img/index-page/known-from/cz/logo_metro_cz.png',
					description: 'Logo from Metro CZ'
				},
				{
					link: 'https://www.penize.cz/tiskove-zpravy/453292-123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter',
					img: '/img/index-page/known-from/cz/logo_penize_cz.png',
					description: 'Logo from Penize',
					size: {
						width: 'auto',
						height: 70
					}
				},
				{
					link: 'https://www.drbna.cz/protext/11338-123-shared-mobility-czechia-sro-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter.html',
					img: '/img/index-page/known-from/cz/logo_drbna.png',
					description: 'Logo from DRBNA'
				}
			],
			[
				{
					link: 'https://www.finmag.cz/tiskove-zpravy/453292-123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter',
					img: '/img/index-page/known-from/cz/logo_finmag.png',
					description: 'Logo from FINMAG',
					size: {
						width: 'auto',
						height: 70
					}
				},
				{
					link: 'https://www.ekonews.cz/protext/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter/',
					img: '/img/index-page/known-from/cz/logo_ekonews.png',
					description: 'Logo from Ekonews',
					size: {
						width: 'auto',
						height: 70
					}
				},
				{
					link: 'https://www.barrandov.tv/rubriky/zpravy/protext/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter_30619.html',
					img: '/img/index-page/known-from/cz/logo_barrandov.png',
					description: 'Logo from TV Barrandov',
					size: {
						width: 'auto',
						height: 70
					}
				},
				{
					link: 'https://svethospodarstvi.cz/ctk-protext/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter/',
					img: '/img/index-page/known-from/cz/logo_sh.png',
					description: 'Logo from SH',
					size: {
						width: 'auto',
						height: 70
					}
				}
			],
			[
				{
					link: 'https://www.wn24.cz/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter/',
					img: '/img/index-page/known-from/cz/logo_wn_24.png',
					description: 'Logo von World News 24',
					size: {
						width: 'auto',
						height: 70
					}
				},
				{
					link: 'https://zivotvcesku.cz/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter/',
					img: '/img/index-page/known-from/cz/logo_zivot.png',
					description: 'Logo from Zivotvcesku CZ',
					size: {
						width: 'auto',
						height: 70
					}
				},

				{
					link: 'https://www.i60.cz/protext/16798/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter',
					img: '/img/index-page/known-from/cz/logo_i60.png',
					description: 'Logo from i60 CZ',
					size: {
						width: 'auto',
						height: 70
					}
				}
			]
		]
	} as IDataPerBu,
	hu: {
		// Home.vue
		youtube_id: 'f0FEMwMhG_8',
		partner_youtube_id: '9y1SopdXK9c',
		phone_fee: 3.64,
		email_jobs: 'karriere@123-transporter.at',
		email_fleet_partner: 'ugyfelszolgalat@123-transporter.hu',
		email_location_partner: 'ugyfelszolgalat@123-transporter.hu',
		long_term_rent_allowed: true,

		partners: [
			{
				id: '01920f3b-db13-7095-b06a-02a6f251d167',
				name: 'obi',
				route_name: routesNames.partner_obi,
				url: getAllPaths(routesNames.partner_obi),
				t_key: 'official_partners.obi',
				logo: '/img/partners/logo_obi.svg',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_obi.svg',
				locations: []
			}
		] as IPartner[],
		trucks: {
			min_size_in_m3: {
				l: 6,
				xl: 11,
				xxl: 11
			},
			price_filter: { min: 8990, max: 12990, step: 1000 }
		},
		map_center: {
			lat: 47.4979,
			lng: 19.0402,
			zoom: 9
		},
		map_center_mobile: {
			lat: 47.4979,
			lng: 18.9,
			zoom: 9
		},
		// Assets
		images: {
			home_pricing_cards: {
				l: '/img/pricing_cards/pricing-card-l.webp',
				xl: '/img/pricing_cards/pricing-card-xl.webp',
				xxl: '/img/pricing_cards/pricing-card-xxl.webp'
			},
			truck_side_mockups: {
				l: '/img/truck_mockups/L_ready.png',
				xl: '/img/truck_mockups/XL_ready.png',
				xxl: '/img/truck_mockups/XXL_ready.png'
			},
			jobs: {
				second_box: '/img/jobs/Karriere-Seite.jpg'
			},
			sustainability: {
				second_box: '/img/sustainability/sustain_2.jpg'
			},
			our_app: {
				top_section: '/img/app-info/top_section.png',
				how_section: '/img/app-info/how_section.png',
				discover_section: '/img/app-info/discover_section.png',
				dl_section: '/img/app-info/download_section.png'
			},
			about_us: {
				second_image: '/img/about-us/IMG_0750.jpg',
				fourth_image: '/img/about-us/IMG_0755.jpg'
			},
			location_partner: {
				van: '/img/Left_Side_45_HU.png'
			}
		},
		media_logos: [
			[
				{
					size: {
						width: 200,
						height: 52
					},
					link: 'https://www.diepresse.com/',
					img: '/img/index-page/known-from/logo_die_presse.png',
					description: 'Logo von Die Presse'
				},
				{
					size: {
						width: 180,
						height: 41
					},
					link: 'https://www.kurier.at',
					img: '/img/index-page/known-from/logo_kurier.png',
					description: 'Logo von Kurier'
				},
				{
					size: {
						width: 250,
						height: 30
					},
					link: 'https://brutkasten.com/',
					img: '/img/index-page/known-from/logo_brutkasten.png',
					description: 'Logo von Brutkasten Media'
				},
				{
					size: {
						width: 77,
						height: 70
					},
					link: 'https://www.krone.at/',
					img: '/img/index-page/known-from/logo_kronen_zeitung.png',
					description: 'Logo von Kronen Zeitung unabhängig'
				}
			]
		]
	} as IDataPerBu,
	sk: {
		// Home.vue
		youtube_id: 'UO396AxhyMw',
		partner_youtube_id: 'ec1lha8hiI4',
		phone_fee: null,
		email_jobs: 'support@123-transporter.sk',
		email_fleet_partner: 'support@123-transporter.sk',
		email_location_partner: 'support@123-transporter.sk',
		long_term_rent_allowed: true,

		partners: [
			{
				id: '01958f07-07fe-72c8-982b-4ab9b1942372',
				name: 'obi',
				route_name: routesNames.partner_obi,
				url: getAllPaths(routesNames.partner_obi),
				t_key: 'official_partners.obi',
				logo: '/img/partners/logo_obi.svg',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_obi.svg',
				locations: []
			},
			{
				id: '01959440-064d-7104-b8ef-b98e6e7a4efa',
				name: 'hornbach',
				route_name: routesNames.partner_hornbach,
				url: getAllPaths(routesNames.partner_hornbach),
				t_key: 'official_partners.hornbach',
				logo: '/img/partners/hornbach-logo-new.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/hb_logo_map.png',
				locations: []
			},
			{
				id: '01959443-6bda-712d-9529-177ecb84f023',
				name: 'sconto',
				route_name: routesNames.partner_sconto,
				url: getAllPaths(routesNames.partner_sconto),
				t_key: 'official_partners.sconto',
				logo: '/img/partners/logo_sconto.png',
				logo_height: null,
				logo_width: null,
				map_icon: '/img/partners/map_icon_sconto2.png',
				locations: []
			}
		],
		trucks: {
			min_size_in_m3: {
				l: 6,
				xl: 12,
				xxl: 12
			},
			price_filter: { min: 9, max: 44, step: 5 }
		},
		map_center: {
			lat: 48.7,
			lng: 19.9,
			zoom: 8
		},
		map_center_mobile: { lat: 48.6, lng: 19.2, zoom: 7 },

		// Assets
		images: {
			home_pricing_cards: {
				l: '/img/pricing_cards/pricing-card-l.webp',
				xl: '/img/pricing_cards/pricing-card-xl.webp',
				xxl: '/img/pricing_cards/pricing-card-xxl.webp'
			},
			truck_side_mockups: {
				l: '/img/truck_mockups/l-new-cz-img.png',
				xl: '/img/truck_mockups/xl-new-cz-img.png',
				xxl: '/img/truck_mockups/xxl-new-cz-img.png',
				advantages: '/img/index-page/advantages/van-cz.png'
			},
			jobs: {
				second_box: '/img/jobs/incoolbator.png',
				team: '/img/jobs/cz_team.jpg'
			},
			sustainability: {
				second_box: '/img/jobs/jobs_123_van_cz.jpg'
			},
			our_app: {
				top_section: '/img/app-info/top_section_sk.png',
				how_section: '/img/app-info/how_section_sk.png',
				discover_section: '/img/app-info/discover_section_sk.png',
				dl_section: '/img/app-info/download_section_sk.png'
			},
			about_us: {
				second_image: '/img/about-us/about_2.png',
				fourth_image: '/img/about-us/about_flottenpartner.jpg'
			},
			location_partner: {
				van: '/img/truck_mockups/XL_cz.png'
			}
		},
		media_logos: [
			[
				{
					link: 'https://www.super.cz/clanek/komercni-clanky-revoluce-v-pujcovani-dodavek-dorazila-do-ceska-s-123-transporter-1503442',
					img: '/img/index-page/known-from/cz/logo_super_cz.png',
					description: 'Logo from Super CZ'
				},
				{
					link: 'https://www.metro.cz/protext/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transpor.A240319_152300_metro-protext_air',
					img: '/img/index-page/known-from/cz/logo_metro_cz.png',
					description: 'Logo from Metro CZ'
				},
				{
					link: 'https://www.penize.cz/tiskove-zpravy/453292-123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter',
					img: '/img/index-page/known-from/cz/logo_penize_cz.png',
					description: 'Logo from Penize',
					size: {
						width: 'auto',
						height: 70
					}
				},
				{
					link: 'https://www.drbna.cz/protext/11338-123-shared-mobility-czechia-sro-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter.html',
					img: '/img/index-page/known-from/cz/logo_drbna.png',
					description: 'Logo from DRBNA'
				}
			],
			[
				{
					link: 'https://www.finmag.cz/tiskove-zpravy/453292-123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter',
					img: '/img/index-page/known-from/cz/logo_finmag.png',
					description: 'Logo from FINMAG',
					size: {
						width: 'auto',
						height: 70
					}
				},
				{
					link: 'https://www.ekonews.cz/protext/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter/',
					img: '/img/index-page/known-from/cz/logo_ekonews.png',
					description: 'Logo from Ekonews',
					size: {
						width: 'auto',
						height: 70
					}
				},
				{
					link: 'https://www.barrandov.tv/rubriky/zpravy/protext/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter_30619.html',
					img: '/img/index-page/known-from/cz/logo_barrandov.png',
					description: 'Logo from TV Barrandov',
					size: {
						width: 'auto',
						height: 70
					}
				},
				{
					link: 'https://svethospodarstvi.cz/ctk-protext/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter/',
					img: '/img/index-page/known-from/cz/logo_sh.png',
					description: 'Logo from SH',
					size: {
						width: 'auto',
						height: 70
					}
				}
			],
			[
				{
					link: 'https://www.wn24.cz/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter/',
					img: '/img/index-page/known-from/cz/logo_wn_24.png',
					description: 'Logo von World News 24',
					size: {
						width: 'auto',
						height: 70
					}
				},
				{
					link: 'https://zivotvcesku.cz/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter/',
					img: '/img/index-page/known-from/cz/logo_zivot.png',
					description: 'Logo from Zivotvcesku CZ',
					size: {
						width: 'auto',
						height: 70
					}
				},

				{
					link: 'https://www.i60.cz/protext/16798/123-shared-mobility-czechia-s-r-o-uvadi-na-cesky-trh-revolucni-sluzbu-pujcovani-dodavek-123-transporter',
					img: '/img/index-page/known-from/cz/logo_i60.png',
					description: 'Logo from i60 CZ',
					size: {
						width: 'auto',
						height: 70
					}
				}
			]
		]
	} as IDataPerBu
};
