
import Vue from 'vue';

export default Vue.extend({
	name: 'AppDownload',

	mixins: [],

	props: {
		value: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			appLinkAndroid: 'https://play.google.com/store/apps/details?id=com.transporter123.customer',
			appLinkApple: 'https://apps.apple.com/at/app/123-transporter/id6451486826',
			appLink: 'https://123t.eu/app'
		};
	},

	computed: {
		localValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		}
	},
	methods: {
		handleOutsideClick() {
			this.$emit('close');
		},
		handleEscapeKey(e: KeyboardEvent) {
			e.stopPropagation(); // Prevent the event from propagating to parent dialogs
			this.$emit('close');
		}
	}
});
