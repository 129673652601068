
import Vue from 'vue';
import type { VueConstructor } from 'vue';
import formHelper from '@/mixins/formHelper';
import { VForm } from '@/types';

import UTILS from '@/store/modules/UtilityModule';
import { GACustomEvent } from '@/types/misc_data';

class ContactForm {
	valid: boolean;
	name: string;
	company: string;
	email: string;
	phone: string;
	topic: string;
	text: string;

	constructor() {
		this.valid = false;
		this.name = '';
		this.company = '';
		this.email = '';
		this.phone = '';
		this.topic = '';
		this.text = '';
	}
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof formHelper>>).extend({
	name: 'ContactForm',

	mixins: [formHelper],

	props: {
		longTermRent: Boolean
	},

	data() {
		return {
			form: new ContactForm(),
			topics: Object.values(this.$t('contact.topics')),
			loading: false
		};
	},

	computed: {
		UTILS: () => UTILS,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		formContact(): VForm {
			return this.$refs.formContact as VForm;
		}
	},

	methods: {
		async send() {
			if (!this.formContact.validate()) {
				return;
			}
			this.loading = true;

			if (this.form.phone) {
				this.form.phone = this.form.phone.replace(/ /g, '').replace('+', '00');
			}

			const payload = JSON.parse(JSON.stringify(this.form));
			delete payload.valid;

			if (this.longTermRent) {
				payload.topic = this.$t('general.long_term_rent');
				// send tracking events
				this.$tracking.custom_event(GACustomEvent.lzm_contact);
				this.$tracking.conversion.ltr_conversion();
			}

			const r = await this.UTILS.SEND_CONTACT(payload);

			if (r === false) {
				this.loading = false;
				return;
			}

			(this as any).$snackbar.showMessage({
				content: this.$t('alert.success.message_sent'),
				type: 'success'
			});

			this.loading = false;
			this.clearValues();
		},

		clearValues() {
			this.form = new ContactForm();
			this.formContact.resetValidation();

			this.$emit('close');
		}
	}
});
